exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-altmetall-ankauf-index-js": () => import("./../../../src/pages/altmetall-ankauf/index.js" /* webpackChunkName: "component---src-pages-altmetall-ankauf-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-redux-wrapper-js": () => import("./../../../src/pages/ReduxWrapper.js" /* webpackChunkName: "component---src-pages-redux-wrapper-js" */),
  "component---src-templates-category-index-js": () => import("./../../../src/templates/category/index.js" /* webpackChunkName: "component---src-templates-category-index-js" */),
  "component---src-templates-preise-index-js": () => import("./../../../src/templates/preise/index.js" /* webpackChunkName: "component---src-templates-preise-index-js" */),
  "component---src-templates-product-page-index-js": () => import("./../../../src/templates/product-page/index.js" /* webpackChunkName: "component---src-templates-product-page-index-js" */),
  "component---src-templates-standorte-city-index-js": () => import("./../../../src/templates/standorte-city/index.js" /* webpackChunkName: "component---src-templates-standorte-city-index-js" */),
  "component---src-templates-standorte-index-js": () => import("./../../../src/templates/standorte/index.js" /* webpackChunkName: "component---src-templates-standorte-index-js" */)
}

