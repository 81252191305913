import Transition from "../components/transition"
import { useTranslation } from "gatsby-plugin-react-i18next"
import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import * as styles from "./layout.module.scss"
try {
  require("smooth-scroll")('a[href*="#"]')
} catch (Any) {}

const parseSeoMetas = seoMetas => {
  const htmlSeoMetas = seoMetas?.map(seoMeta => {
    if (seoMeta.hasOwnProperty("property")) {
      if (seoMeta.property !== "og:site_name")
        return <meta property={seoMeta.property} content={seoMeta.value} />
      else return null
    } else if (seoMeta.hasOwnProperty("name")) {
      return <meta name={seoMeta.name} content={seoMeta.value} />
    }
  })
  return htmlSeoMetas || []
}

const getSitenameMeta = () => {
  if (process.env.GATSBY_DOMAIN === "at")
    return <meta property="og:site_name" content="Schrott24.at" />
  else return <meta property="og:site_name" content="Schrott24.de" />
}

const Layout = ({ children, location, pageContext }) => {
  const { pathname, href } = location

  const formatPathname = path => {
    const segments = path.split("/").filter(Boolean)

    return segments.length > 0 ? `${segments.join("/")}` : "schrott24_homepage"
  }

  const prefix = pageContext?.preisePage ? process.env.GATSBY_DOMAIN + "_" : ""

  const seoMetas = parseSeoMetas(pageContext?.seoMetas)

  const locizeKey = prefix + formatPathname(pathname)

  const { t, i18n } = useTranslation()

  return (
    <>
      <Helmet
      /*This way of setting the HTML lang works in an asynchronous dinamic way, to have it at plain HTML 
		  use gatsby-ssr.js setHtmlAttributes({ lang: XXXXX })*/
      /*htmlAttributes={{
          lang: "de",
        }}*/
      >
        <title>{t(`${locizeKey}_title`)}</title>
        <meta name="description" content={t(`${locizeKey}_description`)} />
        <meta name="keywords" content={t(`${locizeKey}_keywords`)} />
        <meta name="author" content="autor"></meta>
        <meta property="locale" content="de_DE" />
        <meta property="url" content={href} />

        <meta property="og:title" content={t(`${locizeKey}_title`)} />
        <meta name="og:description" content={t(`${locizeKey}_description`)} />
        <meta property="og:url" content={href} />
        <meta property="og:type" content="article" />

        {seoMetas.map(seoMeta => seoMeta)}
        {getSitenameMeta()}
        <meta name="twitter:site" content="@schrott24" />
      </Helmet>

      <div className={styles.wrapper}>
        <main>
          {" "}
          <Transition location={location}>{children}</Transition>
        </main>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
